export default {
    common: {
        menu: {
            agent: '代理店管理',
            agentList: '代理店',
            EmployeeManagement: 'ユーザー',
            store: '店舗管理',
            storeList: '店舗',
            storeType: '店舗カテゴリー',
            collapseText: '非表示',
            storeAccount: 'アカウント',
            permit: '権限管理',
            permitList: '権限',
            device: '設備管理',
            asset: '設備',
            bill: '売上管理',
            benefit: '利益分配',
            cashout: '出金履歴',
            orderManage: '注文管理',
            orderList: '注文リスト',
        },
        login: {
            name: 'MT Charge 管理ツール',
            title: 'ログイン',
            placehloder: {
                username: 'MT Charge ID / メールアドレス',
                password: 'パスワード',
            },
            errorTip: 'MT Charge ID / メールアドレスを入力してください。',
            logout: 'ログアウト',
        },
        selectPlaceholder: '選択してください',
        emptyText: 'データがありません。',
        pleaseFillInCompletely: '必須項目を入力してください。',
    },
    agentCreate: {
        agentBasicInfo: '代理店基本情報',
        agentName: '代理店名',
        principalName: '責任者',
        phoneNumber: '電話番号',
        email: 'メールアドレス',
        detailedAddress: '住所',
        agentInfo: '契約情報',
        contractFile: '契約書',
        clickUpload: 'アップロード',
        chargeInfo: '利益分配',
        headCompanyProfit: 'MT Charge分配率',
        bankAccountInfo: '口座情報',
        bankName: '金融機関名',
        branchName: '支店名',
        bankType: '口座種別',
        ordinary: '普通',
        savings: '当座',
        bankAccount: '口座番号',
        accountName: '口座名義',
        cancel: 'キャンセル',
        submit: '保存',
        pleaseEnterAgentName: '代理店名を入力してください。',
        pleaseEnterPrincipalName: '責任者を入力してください。',
        pleaseEnterCorrectPhoneNumber: '正しい電話番号を入力してください。',
        pleaseEnterCorrectEmailFormat: '正しいメールアドレスを入力してください。',
        pleaseEnterDetailedAddress: '住所を入力してください。',
        pleaseEnterContractFile: '契約書をアップロードしてください。',
        pleaseEnterBankName: '金融機関名を入力してください。',
        pleaseEnterBranchName: '支店名を入力してください。',
        pleaseEnterBankAccount: '口座番号を入力してください。',
        pleaseEnterAccountName: '口座名義を入力してください。',
        pleaseFillInCompletely: '必須項目を入力してください。',
        pleaseSelectBankType: '口座種別を入力してください。',
        addSuccess: '完了しました！',
        parentName: '所属',
    },
    agentList: {
        agentId: '管理ID',
        agentName: '代理店名',
        agentLevel: '代理店種類',
        headCompany: 'MTCharge',
        agent: '代理店',
        agentAccount: 'ユーザーID',
        principalName: '責任者名',
        type: '代理店タイプ',
        query: '検索',
        create: '作成',
        operation: '実行',
        modifyProfitInfo: '分配率設定',
        headCompanyProfit: 'MT Charge分配率',
        cancel: 'キャンセル',
        confirm: '確定',
        superiorAgentName: '所属代理店名称',
        principalPhoneNumber: '担当電話番号',
        email: 'メールアドレス',
        belongingPlace: '地域',
        headCompanyProfitRatio: 'MT Charge分配率',
        bankName: '金融機関名',
        branchName: '支店名',
        accountType: '口座種類',
        bankAccount: '口座番号',
        accountName: '口座名義',
        withdrawableAmount: '出金可能額',
        cooperationStatus: '契約状態',
        updateTime: '更新時間',
        createTime: '作成時間',
        pleaseEnterNumberWithTwoDecimalPlaces: '最大2桁の小数点以下の数字を入力してください。',
        modificationSuccess: '修正できました。',
        changeTip: '0～100の整数を入力してください。',
    },
    agentUserItem: {
        agentName: '代理店名',
        userName: 'ユーザー名',
        phoneNumber: '電話番号',
        email: 'メールアドレス',
        loginPassword: 'パスワード',
        role: 'ロール',
        status: 'ステータス',
        enable: '有効',
        disable: '無効',
        cancel: 'キャンセル',
        confirm: '確定',
        pleaseEnteragentName: '代理店を選択してください',
        pleaseEnterUserName: 'ユーザー名を入力してください。',
        pleaseEnterPhoneNumber: '電話番号を入力してください。',
        pleaseEnterEmail: 'メールアドレスを入力してください。',
        pleaseEnterLoginPassword: '半角英数字6文字以上で入力してください。',
        pleaseSelectRole: 'ロールを設定してください。',
        pleaseEnterStatus: 'ステータスを入力してください。',
        edit: '更新',
        saveSuccess: '保存できました。',
        submitErrorTip: '必須項目を入力してください。',
    },
    agentUser: {
        belongingCooperator: '所属代理店',
        loginAccount: 'ユーザーID',
        userName: 'ユーザー名',
        phoneNumber: '携帯番号',
        role: 'ロール',
        search: '検索',
        reset: 'リセット',
        add: '新規追加',
        email: 'メールアドレス',
        isCooperatorResponsiblePerson: '契約関係の責任者でしょうか？',
        is: 'はい/いええ',
        status: 'ステータス',
        enableDisable: '有効/無効',
        operation: '操作メニュー',
        edit: '編集',
        resetPassword: 'パスワードをリセット',
        newPassword: '新しいパスワード',
        cancel: 'キャンセル',
        confirm: '確定',
        addUser: '新規ユーザー追加',
        editUserInfo: 'ユーザー情報の編集',
        resetSuccess: 'リセットが完成しました。',
        enable: '有効',
        disable: '無効',
        yes: 'はい',
        no: 'いいえ',
    },
    deviceAssetManagement: {
        device: '設備シリアルナンバー',
        belongingSubject: '所有者法人',
        deviceType: '設備タイプ',
        businessStatus: '業務形態',
        belongingPersonnel: '所有者',
        search: '検索',
        reset: 'リセット',
        export: 'ダウンロード',
        batchOperation: '一括アップロード',
        cabinetChargingTreasure: 'チャージスタンド',
        deviceStatus: 'ステータス',
        locatedStore: '店舗',
        storeBelonging: '店舗所属法人',
        locatedCabinet: 'スタンド',
        deviceInformation: '設備情報',
        view: '閲覧',
        circulationRecord: '履歴',
        chargingTreasure: 'バッテリーシリアルナンバー',
        positionNumber: 'スロットNo',
        electricity: 'バッテリー残量',
        chargingStatus: '充電状態',
        faultStatus: '故障状態',
        return: '戻る',
        refresh: '更新',
        cabinet: 'スタンド',
        networkStatus: 'ネットワーク状態',
        lastOnlineTime: '最終オンライン時間',
        inTreasureNumber: '装着バッテリー数',
        positionStatus: 'スロット空き状況',
        operation: '操作',
        popUp: 'ポップアップ',
        popUpAll: 'すべてポップアップ',
        uploadFile: 'ファイルをアップロード',
        clickUpload: 'アップロード',
        downloadTemplate: 'テンプレートをダウンロード',
        targetSubject: 'ターゲット主体',
        operationPrompt: '操作のヒント：倉庫に入っていない設備のみ入庫がサポートされ、在庫の設備のみ在庫に戻すことができ、進行中の店舗で設備の移動がサポートされます。',
        cancel: 'キャンセル',
        returnToWarehouse: '入庫をキャンセル',
        deviceTransfer: '設備の移動',
        batchWarehousing: 'バッチ入庫',
        operator: '操作者',
        operationType: '操作タイプ',
        operationTime: '操作時間',
        status: 'ステータス',
        popUpSuccess: 'ポップアップできました。',
        batchErrorTip: 'SNをアップロードし、ターゲット主体を入力してください',
        batchReturnErrorTip: 'SNをアップロードしてください',
        warehouse: '倉庫',
        enRoute: '進行中',
        enterShopFinish: '店舗での設備使用が完了',
        lending: 'レンタル中',
        userShop: '販売',
        userLoss: 'ユーザーが紛失',
        repairing: '修理中',
        loss: '設備が紛失',
        scrap: '廃棄',
        notExist: '存在しない',
        support: 'ラック',
        unkown: '不明',
        offline: 'オフライン',
        online: 'オンライン',
        inCabin: '倉庫にあり',
        offCabin: '倉庫になし',
        poiBelongEmp: '店舗の所属BD',
        addrAble: '倉庫位置が利用可能',
        addrDisable: '倉庫位置が封鎖',
        battery: 'バッテリー',
        popOption: 'バッテリー取り出す操作',
        popUnlock: 'アクティベーション解除済バッテリーを取り出す',
        popLock: 'アクティベーションロックバッテリーを取り出す',
        batchOperationSuccess: '操作できました。',
        actionTitle: '操作のヒント',
        actionBtn: '確定',
    },
    permitIndex: {
        create: '作成',
        operation: '操作',
        edit: '編集',
        authority: '権限',
        addRoleInfo: 'ロール新規作成',
        modifyRoleInfo: 'ロール情報更新',
        roleName: 'ロール名',
        roleIdentification: 'ロールマーク',
        roleDescription: 'ロール説明',
        cancel: 'キャンセル',
        confirm: '確定',
        assignAuthority: '権限設定',
        update: '更新',
        serialNumber: 'ナンバー',
        pleaseEnterRoleName: 'ロール名を入力して下さい。',
        pleaseEnterRoleIdentification: 'ロールマークを入力して下さい。',
        pleaseFillInCompletely: '必須項目を入力してください。',
        saveSuccess: '保存できました！',
        updateSuccess: '更新できました！',
    },
    poiAccount: {
        merchantAccount: 'アカウント',
        email: 'メールアドレス',
        merchantName: 'アカウント名',
        query: '検索',
        savingsOrdinary: '当座/普通',
        operation: '操作',
        edit: '編集',
        resendPassword: 'パスワード再発行',
        merchantInfoModification: '情報更新',
        account: 'ID',
        bankName: '金融機関名',
        branchName: '支店名',
        accountType: '口座種別',
        ordinary: '普通',
        savings: '当座',
        bankAccount: '口座番号',
        accountName: '口座名義',
        cancel: 'キャンセル',
        confirm: '保存',
        cooperationStoreNumber: '契約店舗数',
        withdrawableAmount: '出金可能額',
        updateSuccess: '更新できました！',
        resendPasswordSuccess: 'パスワードを再発行できました。',
        pleaseEnterAccount: '口座番号を入力してください。',
        pleaseEnterCorrectEmailFormat: '正しいメールアドレスを入力してください。',
        pleaseEnterName: '店舗名を入力してください。',
        pleaseEnterBankName: '金融機関名を入力してください。',
        pleaseEnterBranchName: '支店名を入力してください。',
        pleaseEnterBankType: '口座種別を入力してください。',
        pleaseEnterBankAccount: '口座名義を入力してください。',
        pleaseEnterBankNumber: '口座番号を入力してください。',
    },
    poiDetail: {
        basicInfo: '基本情報',
        edit: '編集',
        cooperationInfo: '契約情報',
        remark: '備考',
        inputTip: '50文字以内で入力してください。',
        return: '戻る',
        saveChanges: '保存',
        notYetOpen: '営業前',
        suspendBusiness: '一時営業停止',
        inBusiness: '営業中',
        closed: '営業時間外',
        unrecognizable: '認識できません',
        storeName: '店舗名',
        businessStatus: '営業状態',
        personInChargePhone: '担当者電話番号',
        storeId: '店舗ID',
        businessHours: '営業時間',
        personInChargeEmail: '担当者メールアドレス',
        storeType: '店舗カテゴリー',
        storeAddress: '店舗住所',
        cooperationStatus: '契約状態',
        merchantAccount: 'アカウント',
        belongingAgent: '所属代理店',
        merchantEmail: '店舗メールアドレス',
        belongingBd: '所属BD',
        prompt: '戻る',
        confirmReturn: '戻る',
        saveSuccess: '保存できました！',
        required: '必須',
        unvaild: '正しくない！',
        saveTip: '保存していない情報があります。戻ると保存しませんので、戻ってよろしいでしょうか？',
    },
    poiList: {
        storeId: '店舗ID',
        belongingAgent: '所属代理店',
        query: '検索',
        operation: '操作',
        basicInfo: '基本情報',
        storeName: '店舗名',
        storeType: '店舗カテゴリー',
        businessStatus: '営業状態',
        businessHours: '営業時間',
        storeAddress: '店舗住所',
        personInChargePhoneNumber: '担当者電話番号',
        cooperationStatus: '契約状態',
        belongingId: '所属BD',
        cabinetNumber: '設備数',
        currentTreasureNumber: 'バッテリー数',
        updateTime: '更新時間',
        belongingBD: '所属BD',
        account: 'アカウント',
        export: 'ダウンロード',
        exportSuccessTip: 'ダウンロードできました！',
        benefit: '利益分配',
    },
    poiType: {
        typeName: 'ステータス',
        typeStatus: 'ステータス',
        disable: '無効',
        enable: '有効',
        query: '検索',
        add: '追加',
        name: '名称',
        remark: '備考',
        status: 'ステータス',
        disableEnable: '無効/有効',
        createTime: '作成時間',
        operation: '操作',
        modify: '更新',
        storeTypeName: '店舗カテゴリー',
        cancel: 'キャンセル',
        confirm: '確定',
        submitSuccess: '保存できました。',
        addText: '追加',
        editText: '編集',
        pleaseEnterTypeName: '店舗タイプ名を入力してください',
        pleaseEnterTypeStatus: '清選択状態',
    },
    benefitIndex: {
        paymentRefundDate: '払い戻し日付',
        selectDateRange: '日付範囲を設定してください。',
        orderNumber: '注文番号',
        pleaseEnterOrderNumber: '注文番号を入力してください。',
        store: '店舗',
        search: '検索',
        export: 'ダウンロード',
        billMonth: '請求月',
        billDate: '請求日',
        orderType: '注文の種類',
        isCappedBilling: '上限請求の有無',
        orderAmount: '注文金額',
        storeName: '店舗名',
        defaultAmount: '違約金額',
        rentAmount: 'レンタル金額',
        cooperationMode: '契約形態',
        merchantAccount: 'アカウント',
        agent: '代理店',
        agentName: '代理店名',
        profitSharingRatio: '利益分配率',
        storeProfitSharingRatio: '店舗分配率',
        headquartersProfitSharingRatio: 'MT Charge分配率',
        defaultFeeProfitSharingAmount: '違約金のMT分配額',
        defaultFeeHeadquartersProfitSharingAmount: '違約金のMT Charge分配額',
        rentFeeProfitSharingAmount: 'レンタル金額MT分配額',
        rentFeeHeadquartersProfitSharingAmount: 'レンタル金額MT Charge分配額',
        rentFeeAgentParticipationSharingAmount: 'レンタル金額代理店分配額',
        rentFeeStoreProfitSharingAmount: 'レンタル金額店舗分配額',
        rentFeeAgentProfitSharingAmount: 'レンタル費用代理店分配額',
        headquartersProfitSharingAmount: 'MT Charge 利益分配額',
        headquartersAmount: 'MT Charge金額',
        cutDay: '支払/払戻日付',
        defaultTaxAmount: '違約金額（税）',
        rentTaxAmount: 'レンタル金額（税）',
    },
    cashoutList: {
        initiationDate: '開始日',
        selectDateRange: '日付範囲設定',
        merchantAccountAgent: 'アカウント',
        pleaseEnterMerchantAccountAgent: 'アカウントID/代理店IDを入力してください。',
        payeeAccountName: '入金口座名義',
        pleaseEnterPayeeAccountName: '入金口座名義を入力してください。',
        merchantAccountId: 'アカウントID/代理店ID',
        merchantAccountName: 'アカウント名/代理店名',
        customerType: '消費者の種類',
        merchant: '店舗',
        meituan: '没有这个 美团不提现',
        agent: '代理店',
        withdrawalInitiationTime: '出金時間',
        merchantAccountNameAgentName: '店舗アカウントか代理店名',
        withdrawalAmount: '出金額',
        bankName: '金融機関名',
        branchName: '支店名',
        accountType: '口座種別',
        accountName: '口座名義',
        bankCardNumber: '口座番号',
        search: '検索',
        export: 'ダウンロード',
        billAmount: '請求金額',
        taxAmount: '請求金額（税）',
        required: '必須',
    },
    order: {
        orderList: {
            searchForm: {
                pleaseSelectPh: '選択してください',
                pleaseInputPh: '入力してください',
                orderNumber: '注文番号',
                orderNumberPh: '注文番号入力してください',
                phoneNumber: '携帯番号',
                orderStatus: '注文状態',
                depositType: '預り金有無',
                userId: 'ユーザーID',
                lendPoi: '貸出POI',
                returnPoi: '返却POI',
                batterySn: 'バッテリーSN',
                lendCabinSn: '貸出スタンド',
                returnCabinSn: '返却スタンド',
                lendPoiName: '貸出店舗',
                returnPoiName: '返却店舗',
                createOrderTime: '貸出時間',
                returnTime: '返却時間',
                search: '絞り込み',
                resetting: 'リセット',
                expand: '一覧',
                retract: '折り畳み',
                storeName: '店舗名',
                merchantAccount: '店舗ID',
                createTime: '受注時間',
                updateTime: '時間更新',
                reset: 'リセット',
            },
            tableData: {
                orderId: '受注番号',
                userId: 'ユーザーID',
                createTime: '貸出時間',
                returnTime: '返却時間',
                status: '注文状態',
                payMode: '預り金有無',
                actualFee: '預かり金額',
                lendPoiName: '貸出場所',
                returnPoiName: '返却場所',
                hand: 'オペレーション',
                check: '詳細',
            },
        },
        orderDetail: {
            artificialReturn: '返却設定',
            lendTime: 'ポップアップ時間',
            usageDuration: '利用時間',
            day: '日',
            hour: '時間',
            minute: '分',
            second: '秒',
            returnTime: '返却時間',
            chooseTime: '時間選択してください',
            returnCabin: '返却スタンド',
            returnCabinPd: 'SNを入力してください',
            returnPoiName: '返却店舗',
            remark: '備考',
            actualAmountReceived: '売上',
            return: '返却',
            confirm: '確認',
            cancel: 'キャンセル',
            mustInput: '必須',
            returnTimeRule: '返却時間は現時点より以前の時間に設定してください。',
            artificialReturnConfirm: '返却設定',
            artificialReturnConfirmDes: 'こちらの注文を返却に設定しますか',

            closeOrder: '終了',
            closeOrderDesc: '注文を終了させます。',
            closeOrderConfirm: '注文を終了ますか？',

            stopCharge: '一時課金停止',
            orderAmount: '請求金額',
            suspendTime: '停止時間',
            stopTime24: '24時間',
            stopChargeConfirm: '課金一時停止させますか？',

            refund: '返金',
            originalFee: '支払う金額',
            canRefundFee: '返金可能金額',
            refundFee: '返金額',
            computedRefundFee: '返金額を0円以上に設定してください。',
            refundConfirm: '返金確認',
            refundConfirmMsg1: '{0}の返金を行いますか？',
            refundConfirmMsg2: '既に{0}円を返金しましたが、{1}円の返金を続行しますか？',

            orderId: '注文番号',
            userName: 'ユーザー名',
            mobile: '携帯番号',
            createTime: '受注時間',
            payTime: '支払う日付',
            payId: '支払う番号',
            status: '状態',
            actualFee: '支払う金額',

            lendInfo: '利用詳細',
            lendPoiName: '貸出場所',
            lendPoiId: '貸出POI',
            lendCabinType: '貸出スタンドSN',
            orderLendTime: 'ポップアップ操作した時間',
            returnPoiId: '返却POI',
            returnCabinType: '返却スタンドSN',
            bizReturnTime: '実際返却時間',
            lendBatteryType: 'バッテリーSN',
            returnType: '返却方法',
            returnTypeCase1: '正常',
            returnTypeCase2: '停電',
            returnTypeCase3: '未返却',
            returnTypeCase4: '00ロック',
            returnTypeCase5: '00ロック後紛失',
            returnTypeCase6: '停電00ロック',
            returnTypeCase7: '停電00ロック後紛失',
            returnTypeCase8: '異物挿入',
            manualReturnCase1: '受動',
            orderReturnTime: 'システム返却時間',
            userPhonePower: '携帯最初電量',
            lendPower: 'バッテリー電量',
            chargeType: '返却時確認基準',
            chargeTypeCase1: '通常返却時',
            chargeTypeCase2: '返却設定時',
            chargeTypeCase3: 'スタンド停電時',
            chargeTypeCase4: '消耗計算時',
            chargeTypeCase5: '故障報告時',
            chargeTypeCase6: '問い合わせ時',
            chargeTypeCase7: '00ロック時',
            chargeTypeCase8: '停電00ロック時',

            userOptLog: 'ユーザー利用レポート',
            optType: '作業類型',
            operateUserName: '作業員',
            operateResult: '執行結果',
            optTime: '作業時間',
            manualLog: '作業レポート',
            operateContent: '作業内容',
            optTypeCase1: '{0}決済選定',

            orderFlow: '注文進捗',
            orderFlowStep1: '注文を作成します',
            orderFlowStep2: 'ポップアップ済み',
            orderFlowStep3: '返却済み',
            orderFlowStep4: '完成',
            leaseDuration: 'レンタル時間',

            payInfo: '決済情報',
            orderType: '注文類型',
            depositFee: '預かり金額',
            depositRefundFee: 'システム返金',
            payTpye: '支払方法',
            originFee: 'レンタル費用',
            needPayFee: '支払う金額',
            manualRefundFee: '返金設定',
            chargingPeriod: 'レンタル時間',
            chargingRule: '料金表',
        },
        orderConstants: {
            MAX_LENGTH_VALIDATE: '100文字まで入力可能です。',
            DEPOSIT_TYPE_DESC: {
                DEPOSIT: '預かり金',
                NO_REFUND: '預かり金なし',
            },
            ORDER_STATUS_QUERY_1: '承認待ち',
            ORDER_STATUS_QUERY_2: 'お支払い待ち',
            ORDER_STATUS_QUERY_3: 'レンタル中',
            ORDER_STATUS_QUERY_4: '返却済み',
            ORDER_STATUS_QUERY_5: '注文完了',
            ORDER_STATUS_QUERY_6: '返金設定',
            ORDER_STATUS_QUERY_7: 'ポップアップ中',
            ORDER_STATUS_QUERY_8: '注文が終了しました',
            SOURCE_MAP: {
                Line: 'LINEミニアプリ',
                App: 'アプリ',
            },
        },
        OPT_TYPE_MAP: {
            1000: '注文を作成しました',
            1001: '注文を削除する',
            1101: '支払う方法選定',
            1102: 'パスワードなしの自動引き落としを引き起こす',
            1103: '預かり金の自動返金',
            1104: 'パスワードなしの自動引き落としを引き起こす',
            1105: '手動支払う',
            1201: 'バッテリーをポップアップ指令',
            1202: 'バッテリーをポップアップ',
            1203: 'バッテリーを返却',
            1301: '返却設定',
            1302: '返金設定',
            1303: '注文終了設定',
            1304: '課金一時停止',
            1305: '一時停止時間を延長',
            1310: 'ラウンド作業員により返金',
            1311: '未払い注文の価額変更',
            1312: '自動価額変更',
            1313: '課金一時停止操作を削除します',
            1204: '注文申し立てを作成',
            1205: '申立てが承認されました',
            1206: '申立て失敗しました',
            1207: 'レンタル120時間後返金',
            1208: '店舗より返金申請',
            1401: '違約により請求発生',
            1402: '利用後クーポン付き',
            1403: 'クーポン廃止',
            2100: 'インテリジェントカスタマーサービス完結オーダー',
            2101: '従業員審査',
        },
        RESULT_MAP: {
            0: '済み',
            1: '失敗しました',
        },
        DATEPICKER_SHORTCUTS: {
            today: '今日',
            yesterday: '昨日',
            last7Days: '過去1週間',
            last14Days: '過去14日',
            last30Days: '過去30日',
            last90Days: '過去90日',
        },
        SUSPEND_TIME: {
            '24Hours': '24時間',
        },
    },
    el: {
        locale: 'ja',
        datepicker: {
            selectDate: '日付設定',
            selectTime: '時間設定',
            startTime: '開始時間',
            endTime: '終了時間',
            now: 'たった今',
            ok: '確定',
            month: '月',
            month1: '1月',
            month2: '2月',
            month3: '3月',
            month4: '4月',
            month5: '5月',
            month6: '6月',
            month7: '7月',
            month8: '8月',
            month9: '9月',
            month10: '10月',
            month11: '11月',
            month12: '12月',
            year: '年',
            weeks: {
                sun: '日',
                mon: '月',
                tue: '火',
                wed: '水',
                thu: '木',
                fri: '金',
                sat: '土',
            },
            months: {
                m1: '1月',
                m2: '2月',
                m3: '3月',
                m4: '4月',
                m5: '5月',
                m6: '6月',
                m7: '7月',
                m8: '8月',
                m9: '9月',
                m10: '10月',
                m11: '11月',
                m12: '12月',
            },
        },
        pagination: {
            goto: '次へ',
            pagesize: '件/ページ',
            total: '合計 {total} 件',
            pageClassifier: 'ページ',
        },
    },
};
